import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

function GoogleAnalytics() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize('G-EN26L7HCFT');
    }, []);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);

    return null;
}

export default GoogleAnalytics;